

.carousel-image-container {
    background-image: url('bg_img.png');
    background-size: cover;
    background-position: center;
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; 
    overflow: hidden;
  }
  
  .carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; 
  }
  .dt-sc-heading.text-center h4 {
    text-align: center;
    align-items: center;


  }
  .hero{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(12, 11, 12);
    font-size: 50px !important;
    font-weight: bold;
    margin-top: 100px;

}
  .additional-images-container {
    display: flex;
    justify-content: center; /* Align items horizontally at the center */
    margin-top: 80px; /* Adjust margin as needed */
  }
  
  .product-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%; /* Adjust maximum width for the box */
    margin-right: 20px; /* Add some space between boxes */
  }
  
  .product-box img {
    width: 100%; /* Set width of the image to fill its container */
    max-height: 250px !important; /* Set maximum height for the image */
    margin-right: 20px; /* Add some space between boxes */
  }
  
  .product-details {
    text-align: center;
  }
  
  .product-details h4 {
    margin-bottom: 10px; /* Add space between product name and button */
    font-family: 'Courier New', Courier, monospace;

  }
  
  .add-to-cart {
    background-color: #007bff; /* Example color for the button */
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart:hover {
    background-color: #0056b3; /* Example color for button on hover */
  }
  
  .sale {
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    margin-top: 150px;
    background-color:#FFC0D9;
  }
  
  /* .sale .image-wrapper {
    flex: 0 0 auto; /* Let the image wrapper shrink but not grow */
   
  
  .sale img {
    width: 500px; /* Adjust as needed */
    height: 500px;
    margin-right: 100px;
  }     
  
  /* .sale .description {
    font-size: 200px;
    font-family: 'Courier New', Courier, monospace;
    text-align: right;
    color: black;
    display: flex;
    justify-content: center;
    margin-right: 400px;
    flex: 1;
  } */
  
  .insta-images-container {
    display: flex;
    overflow-x: hidden; /* Hide horizontal overflow */
    justify-content: center;
    margin-top: 80px; /* Add padding to the container */
} 

.insta-images-container > div {
    flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
    margin-right: 10px; /* Add margin between images */
}

.insta-images-container > div:last-child {
    margin-right: 0; /* Remove margin from last image */
}

.insta-images-container img {
    max-width: 100%; /* Ensure images don't exceed their container's width */
    max-height: 250px;
    border-radius: 20px;
}


.best{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(12, 11, 12);
    font-size: 50px !important;
    font-weight: bold;
    margin-top: 300px;
    
}
.abtbest{
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(12, 11, 12);
    font-size: 50px !important;
    font-weight: bold;
    margin-top: 300px;
    
}

.sub-images-container {
  display: flex;
  overflow-x: hidden; /* Hide horizontal overflow */
  justify-content: center;
  padding: 10px; /* Add padding to the container */
  margin-top: 100px;
}

.sub-images-container > div {
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
  margin-right: 70px; /* Add margin between images */
}

.sub-images-container > div:last-child {
  margin-right: 20px; /* Remove margin from last image */
}

.sub-images-container img {
  max-width: 100%; /* Ensure images don't exceed their containe r's width */
  max-height: 250px;
  border-radius: 20px;
}

.sub-image-wrapper {
  width: 300px; /* Adjust as needed */
  height: 700px;
  margin-left: 100px;
}   

.sale .description {
  font-size: 200px;
  font-family: 'Courier New', Courier, monospace;
  text-align: right;
  color: black;
  display: flex;
  justify-content: center;
  margin-right: 400px;
  flex: 1;
}

.about-images-container {
  display: flex;
  gap: 10px;
}

.abt1 {
  width: 300px; /* Adjust the width as needed */
  margin-right: 10px; /* Add margin to the right of abt1 */
  height: 500px;
}

.abt2,
.abt3 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.abt2 img,
.abt3 img {
  width: 100%;
  height: 50%; /* Adjust the height of abt2 and abt3 images */
  object-fit: cover;
  border-radius: 10px;
}

.abtfeat-container {
  display: flex;
  align-items: center;
}

.abtfeat-description {
  padding-left: 20px; /* Adjust as needed */
  margin-left: 20px;
  font-family: 'Courier New', Courier, monospace;
}
.abtdesc{
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.abtfeat-description p {
  margin: 5%;
}

.abtfeat-container img {
  width: 50%; /* Adjust as needed */
  height: 600px;
  
}
.contact-images-container {
  display: flex;
  overflow-x: hidden; /* Hide horizontal overflow */
  padding: 10px; /* Add padding to the container */
}

.contact-images-container > div {
  flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
  margin-right: 10px; /* Add margin between images */
}

.contact-images-container > div:last-child {
  margin-right: 0; /* Remove margin from last image */
}

.contact-images-container img {
  max-width: 100%; /* Ensure images don't exceed their container's width */
  max-height: 250px;
  border-radius: 20px;
}


  .about-heading {
    padding: 50px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(12, 11, 12);
    font-size: 2rem !important;
}

  .melon{
    font-family: 'Courier New', Courier, monospace;
    color: rgb(226, 156, 204);
    font-size: 100px;
    font-style: italic;
    
  }
  .about-us {
    background-image: url('last.png'); 
    background-size: cover;
    max-width: 1500px;
    margin: auto;
    display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  }

.about-content{
  align-items: center;
  display: flex;
  justify-content: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  flex-direction: column;
}
.about-text {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 100px;
}
.test1{
  font-size: 55px;
  color:rgb(9, 9, 9);
  font-weight: bold;
}

.test3{
  font-size: 55px;
  color:rgb(10, 10, 10);
  font-weight: bold;
}
  .about-image {
      width: 50%;
     background-size: cover;

  }
  .about-us h1 {
    padding: 30px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(5, 4, 4) !important;
    font-size: 80px !important;
    font-weight: bold;
  }
  
  .about-us h2 {
    color: #d73ed7;
  }
  .about-us ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left; 
  }
  
  .about-us li {
    margin-bottom: 10px;
  }
  .animated-title {
    text-align: center;
  }
  
  .title {
    font-size: 80px;
    color: #f08de0;
    animation: slideInLeft 3s ease-in-out;
  }
  
  
  @keyframes fallFromTop {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 3;
    }
  }
  
  h1 {
    animation: fallFromTop 1s ease-in-out;
  }
  
  .section-title{
    padding: 50px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(8, 8, 8);
    font-size: 80px !important;
    font-weight: bold;
}
  
.serv{
    padding: 50px;
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
    color: rgb(12, 11, 12);
    font-size: 80px !important;
    font-weight: bold;

}
@keyframes fallFromTop {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 3;
  }
}

.photo-grid-container {
  display: flex;
  justify-content: center; 
}

.photo-grid-container {
  display: flex;
  justify-content: center; 
}

.photo-grid-container {
  display: flex;
  justify-content: center; 
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); 
  gap: 20px; 
  justify-content: center; 
  flex-direction: column;
}

.photo-grid .grid-item {
  position: relative; 
  text-align: center; 
  transition: transform 0.3s ease; 
}

.photo-grid .grid-item:hover {
  transform: scale(1.05); 
}

.photo-grid .grid-item img {
  width: 65%; 
  height: auto; 
  margin: 0 auto; 
}

.photo-grid .grid-item::after {
  content: ""; 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  background-color: aqua !important;
  opacity: 0; 
  transition: opacity 0.3s ease; 
}

.photo-grid .grid-item:hover img {
  opacity: 0.7; 
}

.photo-grid .grid-item p {
  margin-top: 10px;
}

.photo-grid .grid-item button {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.grid-item:last-child {
  margin-right: 0;
}

.grid-item p {
  margin: 10px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  padding-bottom: 20px;
}

.photo-grid .grid-item img {
  transition: transform 0.3s ease; 
}

.photo-grid .grid-item img:hover {
  transform: scale(1.1); 
}
.semfoot {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  /* margin-top: 150px; */
  background-color:#FFC0D9;
}
.dt-sc-deal-banner-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin: 0;
}
.text-center{
  text-align: center;
  justify-content: center;
  margin-right: 200px;
  font-family: 'Courier New', Courier, monospace;
  
}
.login-image{
  width: 10px;
  height: 10px
}

.message-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 5px;
  z-index: 999;
}



/* Container for the products */
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Each product item */
.image-item {
  width: 300px; /* Fixed width for each product item */
  margin: 10px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #fff;
}

.image-item img {
  max-width: 100%;
  height: auto;
}

.image-description {
  margin-top: 10px;
}

.image-description h3 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.image-description p {
  font-size: 1em;
  margin-bottom: 10px;
}

.image-description button {
  width: 100%; /* Ensure button width stays the same */
  padding: 10px;
  background-color: #e38ebe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.image-description button:hover {
  background-color: #c06ea4;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.quantity-container button {
  width: 30px;
  height: 30px;
  font-size: 1em;
  background-color: #e38ebe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.quantity-container button:hover {
  background-color: #c06ea4;
}

.quantity-container input {
  width: 50px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
}
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.spinner {
  border: 6px solid rgba(0, 0, 0, 0.1); /* Increased border thickness */
  border-top: 6px solid #3498db; /* Adjusted top border color */
  border-radius: 50%;
  width: 80px; /* Increased width */
  height: 80px; /* Increased height */
  animation: spin 1s linear infinite;
  margin-top: 20px; /* Adjusted margin-top for spacing */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




