.App {
  text-align: center;
}

.App-logo {
  height: 100vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-image img {
  width: 30px; 
  height: 30px;
  
}

.cart-image img {
  width: 40px; 
  height: 40px;
  

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.Navbar .Nav .nav-link {
  transition: color 0.3s ease; /* Smooth transition for color change */
}
.test{
  font-family: 'Courier New', Courier, monospace;
}

.nav-link:hover {
  color: #e06cc7 !important; /* Change the color on hover */
  text-decoration: underline; /* Add underline on hover */
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Align items with equal space between them */
}

.image-item {
  width: calc(25% - 20px); /* Set width for each item to occupy 25% of the container width */
  margin-bottom: 20px; /* Add margin bottom for spacing between rows */
  display: flex; /* Use flexbox to align items horizontally */
  flex-direction: column; /* Arrange children vertically */
  align-items: center; /* Center items horizontally */
}

.image {
  width: 100%; /* Ensure images fill their respective containers */
  height: auto; /* Maintain aspect ratio */
}

.image-description {
  width: 100%; /* Ensure description takes full width */
  margin-top: 10px; /* Add margin top for spacing */
  text-align: center; /* Center text */
}

.image-description h3 {
  margin-bottom: 5px; 
}

.image-description p {
  margin-bottom: 10px; /* Add margin bottom for spacing */
}

.image-description button {
  background-color: #eca0df;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: auto; /* Allow button width to adjust based on content */
  max-width: 150px; /* Set maximum button width */
  box-sizing: border-box; /* Ensure padding is included in the width */
  transition: background-color 0.3s ease; /* Add transition for smooth color change */
}

.description-button-container {
  display: flex;
  justify-content: space-between; /* Align items with equal space between them */
}

@media (max-width: 767px) {
  .image-item {
    width: 100%; /* Set width to 100% on smaller screens to make them stack vertically */
  }
}

.description-button-container {
  display: flex;
  align-items: center;
}

.haay{
  height: 15rem; 
}

.caption-container {
  margin-top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
}


.shop-now,
.get-started {
  background-color: #da6dbe;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-family: 'Courier New', Courier, monospace;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50px; /* Adjust the value to control the oval shape */
}

.get-started {
  background-color: #da6dbe; /* Adjust color as needed */
}

@media (max-width: 767px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .shop-now,
  .get-started {
    font-size: 14px; 
    padding: 8px 16px; 
  }
}

.description {
  margin-top: 200px; 
  font-family: 'Courier New', Courier, monospace;
  font-size: 40px;
  margin-left: 100px;
  color: #da6dbe;   
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer {
  margin-top: 100px;
  background: #f8f7f4;
  padding: 40px 0;
  color: #222222;
  font-size: 14px;
  text-align: center;
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#footer .credits a {
  color: #e38ebe;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 700px; 
  height: 600px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-item {
  margin: 10px;
}

.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 300px; 
  height: auto; 
  max-height: 300px; 
  max-width: 100%;
  margin-top: 10px; 
  border-radius: 8px;
}

.centered-container {
  text-align: center;
}

.quantity-container label {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.container {
  max-width: 100%;
  margin: auto;
  padding-left: calc(var(--DTGutter_Width) / 2);
  padding-right: calc(var(--DTGutter_Width) / 2);
  width: var(--DTContainer);
}

.search-container {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 20px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
  max-width: 100%;
}
.custom-button {
  background-color:  #e38ebe;
  color: rgb(202, 143, 200); 
}
