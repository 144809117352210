/* Cart.css */

.cart-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  margin-top: 10px; /* Adjust margin-top for spacing */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.cart-item-image-container {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-right: 20px;
}

.cart-item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.cart-item-details {
  flex-grow: 1;
}

.update-container {
  display: flex;
  align-items: center;
}

.update-container input {
  width: 50px;
  margin-right: 10px;
}

.cancel-update-button {
  margin-left: 10px;
  background-color: red;
  color: white;
}

.total-price {
  margin-top: 20px;
  text-align: right;
  font-size: 1.5em;
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #2980b9;
}

input[type="number"] {
  width: 50px;
  padding: 4px;
}

input[type="number"]:focus {
  outline: none;
}

@media (max-width: 600px) {
  .cart-item {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-details {
    text-align: center;
    margin-top: 10px;
  }
}
