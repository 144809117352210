/* Contact.css */

.contact-container {
    padding: 40px;
    background-color: #f9f9f9;
  }
  
  .contact-info {
    margin-bottom: 40px;
  }
  
  .contact-title {
    color: #333;
    font-size: 3rem;
    font-weight: bold;
    text-align: center;
  }
  
  .info-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  .info-item i {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .info-text {
    flex: 1;
  }
  
  .contact-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .php-email-form .form-group {
    margin-bottom: 15px;
  }
  
  .php-email-form input[type="text"],
  .php-email-form input[type="email"],
  .php-email-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .php-email-form textarea {
    resize: vertical;
  }
  
  .custom-button {
    background-color: #2ecc71; /* Updated button background color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block;
    margin: 0 auto; /* Center the button horizontally */
  }
  
  .custom-button:hover {
    background-color: skyblue; /* Updated button hover color */
  }
  
  .alert {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 4px;
    position: relative; /* Ensure relative positioning for spinner */
  }
  
  .alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  
  .best-partners {
    margin-top: 40px;
    text-align: center;
  }
  
  .partners-title {
    color: #333;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .sub-images-container {
    display: flex;
    justify-content: center;
  }
  
  .sub-images-container img {
    max-width: 100%;
    height: auto;
    margin: 0 10px;
  }
  
  /* Spinner Styles */
  .spinner-border {
    width: 2rem;
    height: 2rem;
    margin: 0 auto; /* Center the spinner horizontally */
    position: absolute; /* Position spinner absolutely within alert */
    top: 50%; /* Adjust positioning */
    left: 50%; /* Adjust positioning */
    transform: translate(-50%, -50%); /* Center the spinner precisely */
  }
  
  .spinner-border.text-primary {
    color: #007bff; /* Adjust spinner color */
    width: 3rem;
    height: 3rem;
    border-width: 0.25em; /* Adjust border width */
  }
  
  .spinner-border.text-primary > .visually-hidden {
    font-size: 10px; /* Adjust size of visually hidden text */
  }
  
  @media (prefers-reduced-motion: reduce) {
    .spinner-border {
      animation: none; /* Disable animation for reduced motion */
    }
  }
  